import * as React from "react";

import Layout from "../components/Base/Layout";
import SendPasswordResetEmailForm from "../components/Login/SendPasswordReset";
import UnAuthContent from "../components/Login/UnAuthContent";

export default function ForgotPassword() {
  return (
    <Layout>
      <UnAuthContent>
        <SendPasswordResetEmailForm />
      </UnAuthContent>
    </Layout>
  );
}
