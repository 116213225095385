import * as React from "react";

import { ReactNode, useEffect } from "react";

import Loading from "../HelperComponents/Loading"
import { navigate } from "gatsby";
import useAuth from "../hooks/useAuth";
import { useToast } from '@chakra-ui/react';

export default function UnAuthContent({ children }: { children: ReactNode }) {
  const { loggedIn, loading } = useAuth();
  const toast = useToast()

  // Navigate authenticated users to Shop page.
  useEffect(() => {
    if (!loading && loggedIn) {
      navigate('/shop');
    }
  }, [loggedIn, loading, navigate]);

  if (!loggedIn) {
    return <>{children}</>;
  }

  return  <Loading></Loading>;
}